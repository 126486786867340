import { IconButton, Typography } from "@material-ui/core"
import ArrowBackIosOutlinedIcon from "@material-ui/icons/ArrowBackIosOutlined"
import ArrowForwardIosOutlinedIcon from "@material-ui/icons/ArrowForwardIosOutlined"
import { GeelyCarModelResponse } from "api"
import BottomButtons from "common/BottomButtons"
import { Desktop } from "common/MediaQueries"
import NextImage from "common/NextImage"
import { useState } from "react"
import styled from "styled-components"
import SwiperCore, { A11y, Keyboard, Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import PlayButton from "../../../common/PlayButton"
import VideoPopup from "../SEMSystem/VideoPopup"

const StyledMainContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
  ${(p) => p.theme.breakpoints.down("sm")} {
    display: flex;
    flex-direction: column;
  }
`

const StyledSwiper = styled(Swiper)`
  width: 100%;
  height: 100%;
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 100%;
    position: relative;
  }
  .swiper-slide {
    margin-right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    ${(p) => p.theme.breakpoints.down("sm")} {
      height: 300px;
    }
  }
`
const SwiperImage = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  top: 0;
  left: 0;
  ${(p) => p.theme.breakpoints.up("md")} {
    height: 100%;
  }
`
const DesktopCol = styled.div`
  ${(p) => p.theme.breakpoints.up("md")} {
    display: flex;
    width: 100%;
    flex-direction: column;
    bottom: 0px;
    z-index: 1;
    position: absolute;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
    padding: 50px 90px 30px 90px;
  }
`
const StyledBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  ${(p) => p.theme.breakpoints.down("sm")} {
    min-height: 250px;
  }
  ${(p) => p.theme.breakpoints.up("md")} {
    flex-direction: row-reverse;
    justify-content: space-between;
    z-index: 1;
    width: 100%;
    margin-bottom: 30px;
    align-items: center;
  }
`
const StyledNavigation = styled.div`
  pointer-events: all;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 120px;
  margin-top: 25px;
  color: ${(p) => p.theme.colors.midnight};
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 215px;
    margin-top: 40px;
    color: ${(p) => p.theme.colors.white};
  }
`
const StyledText = styled(Typography)`
  font-size: 26px;
  text-align: center;
  margin-top: 28px;
  font-weight: 300;
  ${(p) => p.theme.breakpoints.up("md")} {
    font-weight: normal;
    text-align: right;
    font-size: 38px;
    margin-top: 40px;
    color: ${(p) => p.theme.colors.white};
    width: 300px;
    justify-content: flex-start;
  }
`
const DesktopSpacer = styled(Typography)`
  ${(p) => p.theme.breakpoints.up("md")} {
    width: 300px;
    justify-content: flex-end;
  }
`
const StyledPlayButton = styled(PlayButton)`
  ${(p) => p.theme.breakpoints.up("md")} {
    margin-bottom: 200px;
  }
`
const Gallery = ({ data }: { data: GeelyCarModelResponse }) => {
  const [active, setActive] = useState(0)
  const [swiperController, setSwiperController] = useState<SwiperCore>(null)
  const [openModal, setOpenModal] = useState(false)
  const gallery = data.gallery
  return (
    <StyledMainContainer>
      <StyledSwiper
        modules={[Pagination, Navigation, A11y, Keyboard]}
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setOpenModal(false)
          setActive(swiper.activeIndex)
        }}
        onSwiper={setSwiperController}
        aria-label={`סליידר של ${gallery?.length} תמונות`}
        a11y={{
          prevSlideMessage: "התמונה הקודמת",
          nextSlideMessage: "התמונה הבאה",
          slideRole: "img",
          containerMessage: "סליידר של תמונות",
          firstSlideMessage: "התמונה הראשונה",
          lastSlideMessage: "התמונה האחרונה",
        }}
        keyboard={{
          enabled: true,
        }}
      >
        {gallery.map((slide, index) => (
          <SwiperSlide key={index}>
            <SwiperImage
              aria-label={
                index === gallery.length - 1
                  ? `סוף תמונות`
                  : index === 0
                  ? `תחילת תמונות`
                  : `תמונה מספר ${index + 1} בסליידר`
              }
            >
              <NextImage
                image={slide?.image?.imageUrl}
                imageSizes={"100vw"}
                alt={slide?.image?.alt}
              />
            </SwiperImage>

            {/* if there is a video url, render videoPlayer with image thumbnail. Otherwise, render image */}
            {slide.video?.imageUrl.length && index === active && (
              <>
                <StyledPlayButton onClick={() => setOpenModal(true)} />
                <VideoPopup
                  url={slide.video.imageUrl}
                  open={openModal}
                  onClose={setOpenModal}
                  label={slide.mainTitle}
                />
              </>
            )}
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <DesktopCol>
        <StyledBottomContainer>
          <Desktop>
            <DesktopSpacer />
          </Desktop>
          <StyledNavigation>
            {/* make arrow color disabled if there is no previous */}
            <IconButton
              aria-label="כפתור לתמונה הקודמת"
              onClick={() => swiperController?.slidePrev()}
              disabled={active === 0}
              color="inherit"
            >
              <ArrowForwardIosOutlinedIcon
                fontSize="large"
                color={active === 0 ? "disabled" : "inherit"}
              />
            </IconButton>
            <IconButton
              aria-label=""
              onClick={() => swiperController?.slideNext()}
              disabled={active === gallery.length - 1}
              color="inherit"
            >
              <ArrowBackIosOutlinedIcon
                fontSize="large"
                color={active === gallery.length - 1 ? "disabled" : "inherit"}
              />
            </IconButton>
          </StyledNavigation>
          {gallery[active] && (
            <StyledText>{gallery[active].mainTitle}</StyledText>
          )}
        </StyledBottomContainer>
        <Desktop>
          <BottomButtons />
        </Desktop>
      </DesktopCol>
    </StyledMainContainer>
  )
}

export default Gallery
